import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import fr from 'vuetify/es5/locale/fr'

Vue.use(Vuetify, {
  theme: {
    primary: '#00BCFF',
    dark: '#007199',
    light: '#99E4FF',
    backgroundColor: {
      base: '#F3F5F7',
      darken1: '#44525B',
    },
    borderColor: '#E5E7E9',
    labelColor: '#536975',
    tooltipBackgroundColor: '#41525C',
    negativeColor: '#CB3A24',
    positiveColor: '#9FCA65',
    textColor: '#000000',
  },
  options: {
    customProperties: true,
  },
  lang: {
    locales: { fr, },
    current: 'fr',
  },
  iconfont: 'mdi',
},)
