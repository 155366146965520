import TopToolbar from '@/components/shared/pageSections/headers/TopToolbar/TopToolbar.vue'

export default {
  name: 'ConnectedLayout',
  components: {
    TopToolbar,
  },
  async beforeMount() {
    await this.$store.dispatch('initStore',)
  },
  computed: {
    isStoreInitiated() {
      return this.$store.state.isStoreInitiated
    },
  },
}
