import { privateApiConnectorService, } from '../../../services'

export default {
  name: 'CreateUser',
  data() {
    return {
      error: null,
      password: this.generateRandomPassword(),
      supplierId: '',
      email: '',
      requestPending: false,
      showCopyToClipboardTooltip: false,
    }
  },
  computed: {
    suppliersList() {
      return this.$store.getters.getSuppliersList.map((s,) => {
        return { text: s.supplier_name, value: s.id, }
      },)
    },
    isEmailNotEmpty() {
      this.error = null
      return this.isNotEmpty(this.email,) || 'L\'email est obligatoire'
    },
    isEmailWellFormatted() {
      this.error = null
      return this.isEmailValid(this.email,) || 'Le format de l\'email n\'est pas valide'
    },
    submitDisabled() {
      return (
        !this.isEmailValid(this.email,) ||
        !this.isNotEmpty(this.email,) ||
        !this.isValidPassword(this.password,) ||
        this.supplierId.length === 0
      )
    },
  },
  methods: {
    isValidPassword(password,) {
      return password.match('^.*(?=.{10,})((?=.*[!@#$%^&*()\\-_=+{};:,<.>]))(?=.*\\d)((?=.*[a-z]))((?=.*[A-Z])).*$',)
    },
    generateRandomPassword() {
      let res = ''
      const characters = '!@#$%&-_=+<>0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      while (!this.isValidPassword(res,)) {
        if (res.length > 15) res = res.slice(5,)
        res = res + characters[Math.floor(Math.random() * characters.length,)]
      }
      return res
    },
    generateNewPassword() {
      this.password = this.generateRandomPassword()
    },
    isNotEmpty(value,) {
      return !(!value || value.length === 0)
    },
    isEmailValid(value,) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value,)
    },
    copyToClipboard() {
      document.getElementById('password-text-field',).select()
      document.execCommand('copy',)
      this.showCopyToClipboardTooltip = true
    },
    resetInputs() {
      this.password = this.generateRandomPassword()
      this.email = ''
      this.supplierId = ''
      this.error = null
    },
    async createUser() {
      this.requestPending = true
      try {
        await privateApiConnectorService.createUser(this.email, this.password, this.supplierId,)
        this.closePopin()
      } catch (error) {
        this.error = 'Votre saisie n\'a pas pu être enregistrée. Il s\'agit soit d\'un problème technique soit l\'utilisateur existe déjà.'
        console.log(error,)
      } finally {
        this.requestPending = false
      }
    },
    closePopin() {
      this.resetInputs()
      this.$emit('closePopin',)
    },
  },
}
