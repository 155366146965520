import axios from 'axios'

const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'access_token'

export default class PrivateApiConnectorService {
  constructor(apiHost,) {
    this.apiHost = apiHost
  }

  get api() {
    return axios.create({
      baseURL: `${this.apiHost}/`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY,)}`,
      },
    },)
  }

  async getSuppliersList() {
    const suppliersList = this.getFromApi('/suppliers',)
    return suppliersList
  }

  async getUsersList() {
    const usersList = await this.getFromApi('/users',)
    return usersList
  }

  async deleteUser(userId,) {
    await this.api
      .delete('/users', { data: { userId, }, },)
      .then(() => {
        return 'Votre message a bien été envoyé, nous vous répondrons dès que possible.'
      },)
      .catch((error,) => {
        if (error.response.status === 401) {
          return 'You must be logged in to send a message'
        }
        throw error
      },)
  }

  async createUser(email, password, supplierId,) {
    await this.api
      .post('/users', { email, password, supplierId, },)
      .then((response,) => {
        return 'Votre message a bien été envoyé, nous vous répondrons dès que possible.'
      },)
      .catch((error,) => {
        if (error.response.status === 401) {
          return 'You must be logged in to send a message'
        }
        throw error
      },)
  }

  async getAppProviderName() {
    const apiResponse = await this.getFromApi('/users/me/associated-purchaser',)
    return apiResponse.purchaser
  }

  async getSupplierMarketSegmentsList(userId,) {
    return this.getFromApi('/users/' + userId + '/market-segments',)
  }

  async getFullMarketSegmentsList() {
    return this.getFromApi('/segments-de-marche',)
  }

  async updateSupplierMarketSegments(userId, marketSegmentsList,) {
    await this.api
      .put('/users/' + userId + '/market-segments', { marketSegments: marketSegmentsList, },)
      .then((response,) => {
        return 'Votre demande de mise à jour des segments de marchés autorisés a bien été prise en compte.'
      },)
      .catch((error,) => {
        if (error.response.status === 401) {
          return 'You must be logged in to update markets segments granted for a supplier'
        }
        throw error
      },)
  }

  async downloadExportUsers(portal, filters,) {
    const query = `/dl-users?portal=${portal}&email=${filters.email}&supplier=${filters.supplier}`

    await this.api
      .get(query,)
      .then((response,) => {
        const filename = `[Polposition] Export Utilisateurs - ${portal}.csv`
        const url = window.URL.createObjectURL(new Blob([response.data,],),)
        const link = document.createElement('a',)
        link.href = url
        link.setAttribute('download', filename,)
        document.body.appendChild(link,)
        link.click()
      },)
      .catch((error,) => {
        throw error
      },)
  }

  async getFromApi(apiRoute,) {
    return this.api
      .get(apiRoute,)
      .then((response,) => {
        return response.data
      },)
      .catch((error,) => {
        throw error
      },)
  }
}
