import { privateApiConnectorService, } from '@/services'

const APP_PROVIDER_SETTINGS_DATA = {
  PPA: {
    displayName: 'PPA',
    purchaserColor: '#5BAF99',
  },
  AREA: {
    displayName: 'Area',
    purchaserColor: '#D78933',
  },
  'UNION UNISUD': {
    displayName: 'UNISUD',
    purchaserColor: '#9DBC3E',
  },
  AAA: {
    displayName: 'AAA',
    purchaserColor: '#2B627F',
  },
  INOXA: {
    displayName: 'Inoxa',
    purchaserColor: '#EEB53E',
  },
  UTDF: {
    displayName: 'UTDF',
    purchaserColor: '#235AA2',
  },
  'SICA AXEREAL': {
    displayName: 'Axéréal',
    purchaserColor: '#4CA247',
  },
  'CENTRALE CONVERGENCE': {
    displayName: 'CONVERGENCE',
    purchaserColor: '#296145',
  },
}

const state = {
  appProvider: null,
}

const getters = {
  getAppProvider: (state,) => state.appProvider,
  getAppProviderColor: (state,) => (APP_PROVIDER_SETTINGS_DATA[state.appProvider] || {}).purchaserColor,
  getAppProviderDisplayName: (state,) => (APP_PROVIDER_SETTINGS_DATA[state.appProvider] || {}).displayName,
  isPpaUser: (state,) => state.appProvider === 'PPA',
}

const mutations = {
  setAppProvider(state, appProvider,) {
    state.appProvider = appProvider
  },
}

const actions = {
  async fetchAppProvider({ commit, },) {
    const appProviderData = await privateApiConnectorService.getAppProviderName()
    commit('setAppProvider', appProviderData,)
  },
}

export default {
  getters,
  actions,
  mutations,
  state,
}
