import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login/Login.vue'
import ResetPassword from './views/ResetPassword/ResetPassword.vue'
import ErrorPage from './views/Error/Error.vue'
import { authService, } from '@/services'
import UnavailableService from './views/UnavailableService/UnavailableService.vue'
import ConnectedLayout from './views/ConnectedLayout/ConnectedLayout.vue'
import AdminDashboard from './views/AdminDashboard/AdminDashboard.vue'

if (process.env.NODE_ENV !== 'test') {
  Vue.use(Router,)
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      meta: { requiresAuth: true, },
      component: ConnectedLayout,
      children: [
        {
          path: '',
          name: 'home',
          component: AdminDashboard,
        },
      ],
    },
    {
      path: '/connexion',
      name: 'connexion',
      component: Login,
    },
    {
      path: '/reinitialisation-mot-de-passe',
      name: 'reset-password',
      component: ResetPassword,
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorPage,
    },
    {
      path: '/unavailable',
      name: 'unavailable',
      component: UnavailableService,
    },
    {
      path: '*',
      redirect: { name: 'error', },
    },
  ],
},)

router.beforeEach(async (to, from, next,) => {
  const auth = authService.isAuthenticated()
  if (to.matched.some((record,) => record.meta.requiresAuth,)) {
    if (!auth) {
      next({
        name: 'connexion',
      },)
    } else {
      next()
    }
  } else {
    next()
  }
},)

export default router
