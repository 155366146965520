import { privateApiConnectorService, } from '../../../services'

export default {
  name: 'DeleteUser',
  props: ['userObject',],
  data() {
    return {
      error: null,
      requestPending: false,
    }
  },
  methods: {
    async deleteUser(userId,) {
      this.requestPending = true
      try {
        await privateApiConnectorService.deleteUser(userId,)
        this.closePopin()
      } catch (error) {
        this.error =
          'Suite à un problème technique, l\'utilisateur n\'a pas pu être supprimé. Veuillez nous excuser. Nous mettons tout en oeuvre pour y remédier.'
        console.log(error,)
      } finally {
        this.requestPending = false
      }
    },
    closePopin() {
      this.error = null
      this.$emit('closePopin',)
    },
  },
}
