import CreateUser from '../../components/AdminDashboard/CreateUser/CreateUser.vue'
import DeleteUser from '../../components/AdminDashboard/DeleteUser/DeleteUser.vue'
import SelectUserMarketSegments from '../../components/AdminDashboard/SelectUserMarketSegments/SelectUserMarketSegments.vue'
import FilterExport from '../../components/FiltersExport/FiltersExport.vue'

export default {
  name: 'AdminDashboard',
  components: {
    CreateUser,
    DeleteUser,
    SelectUserMarketSegments,
    FilterExport,
  },
  data() {
    return {
      createDialog: false,
      deleteDialog: false,
      userToBeDeleted: '',
      userToUseForSegmentsUpdate: {},
      chooseSegmentsDialog: false,
      showCopyToClipboardTooltip: false,
    }
  },
  computed: {
    portalUsersData() {
      const emailFilter = this.$store.getters.getEmailFilter || ''
      const suppliersFilter = this.$store.getters.getSuppliersFilter || ''
      const users = this.$store.getters.getPortalUsers
      let result = emailFilter === '' ? users : users.filter((p,) => p.email.includes(emailFilter,),)
      result = suppliersFilter === '' ? result : result.filter((p,) => p.supplier_name === suppliersFilter,)

      return result
    },
    appProviderName() {
      return this.$store.getters.getAppProviderDisplayName
    },
    isPpaUser() {
      return this.$store.getters.isPpaUser
    },
    columnHeaders() {
      return [
        {
          text: '<div class="text-label--cap">Adresse email</div>',
          value: 'email',
        },
        {
          text: '<div class="text-label--cap">Fournisseur</div>',
          value: 'supplier',
        },
        {
          text: '<div class="text-label--cap">Role</div>',
          value: 'role',
        },
        {
          text: '<div class="text-label--cap">Actions</div>',
          value: 'action',
        },
      ]
    },
  },
  methods: {
    async closeCreateDialog() {
      this.createDialog = false
      await this.$store.dispatch('fetchUsers',)
    },
    openDeleteDialog(user,) {
      this.userToBeDeleted = user
      this.deleteDialog = true
    },
    async closeDeleteDialog() {
      this.deleteDialog = false
      await this.$store.dispatch('fetchUsers',)
    },
    openChooseSegmentsDialog(userId,) {
      this.userToUseForSegmentsUpdate = {}
      if (userId) {
        const userObject = this.$store.getters.getPortalUsers.find((portalUser,) => portalUser.userId === userId,)
        if (userObject) {
          this.userToUseForSegmentsUpdate = {
            userId: userObject.userId,
            supplier_name: userObject.supplier_name,
            email: userObject.email,
          }
        }
      }
      this.chooseSegmentsDialog = true
    },
    closeChooseSegmentsDialog() {
      this.chooseSegmentsDialog = false
    },
  },
}
