import { authService, } from '../../../../../services/index'

export default {
  name: 'TopToolbar',
  computed: {
    appProviderColor() {
      return this.$store.getters.getAppProviderColor
    },
  },
  methods: {
    logout() {
      return authService.logout()
    },
  },
}
