import Vue from 'vue'
import Vuex from 'vuex'
import { privateApiConnectorService, authService, } from '@/services'
import appProviderSettingsModule from './modules/appProviderSettings'
import router from '@/router'

Vue.use(Vuex,)

const state = {
  isStoreInitiated: false,
  authenticated: false,
  portalUsers: [],
  suppliersList: [],
  fullMarketList: [],
  emailFilter: '',
  suppliersFilter: '',
}

export const mutations = {
  setFullMarketList(state, fullMarketList,) {
    state.fullMarketList = fullMarketList
  },
  setIsStoreInitiated(state, isStoreInitiated,) {
    state.isStoreInitiated = isStoreInitiated
  },
  setIsAuthenticated(state, isActuallyAuthenticated,) {
    state.authenticated = isActuallyAuthenticated
  },
  setPortalUsers(state, portalUsers,) {
    state.portalUsers = portalUsers
  },
  setSuppliersList(state, suppliersList,) {
    state.suppliersList = suppliersList
  },
  setEmailFilter(state, emailFilter,) {
    state.emailFilter = emailFilter
  },
  setSuppliersFilter(state, suppliersFilter,) {
    state.suppliersFilter = suppliersFilter
  },
}

export const getters = {
  isAuthenticated: (state,) => state.authenticated,
  getPortalUsers: (state,) => state.portalUsers,
  getSuppliersList: (state,) => state.suppliersList,
  getFullMarketList: (state,) => state.fullMarketList,
  getEmailFilter: (state,) => state.emailFilter,
  getSuppliersFilter: (state,) => state.suppliersFilter,
}

const handleApiResponseErrorStatus = (error,) => {
  if (error.response.status === 401) {
    authService.logout()
  } else if (error.response.status === 403) {
    router.replace({ name: 'error', },)
  } else if (error.response.status === 503) {
    router.replace({ name: 'unavailable', },)
  }
}

export const actions = {
  async initStore({ commit, dispatch, },) {
    commit('setIsAuthenticated', true,)
    try {
      dispatch('fetchAppProvider',)
      dispatch('fetchUsers',)
      dispatch('fetchSuppliers',)
      dispatch('fetchFullMarketList',)
      commit('setIsStoreInitiated', true,)
    } catch (error) {
      handleApiResponseErrorStatus(error,)
    }
  },
  async fetchUsers({ commit, },) {
    try {
      const portalUsers = await privateApiConnectorService.getUsersList()
      commit('setPortalUsers', portalUsers,)
    } catch (error) {
      handleApiResponseErrorStatus(error,)
    }
  },
  async fetchSuppliers({ commit, },) {
    try {
      const suppliersList = await privateApiConnectorService.getSuppliersList()
      commit('setSuppliersList', suppliersList,)
    } catch (error) {
      handleApiResponseErrorStatus(error,)
    }
  },
  async fetchFullMarketList({ commit, },) {
    try {
      const fullMarketList = await privateApiConnectorService.getFullMarketSegmentsList()
      commit('setFullMarketList', fullMarketList,)
    } catch (error) {
      handleApiResponseErrorStatus(error,)
    }
  },
}

export default new Vuex.Store({
  getters,
  actions,
  mutations,
  state,
  modules: {
    appProviderSettingsModule,
  },
},)
