import axios from 'axios'

export default class ApiConnectorService {
  constructor(apiHost,) {
    this.api = axios.create({
      baseURL: `${apiHost}/`,
    },)
  }

  get() {
    return this.api.get('/',).then((response,) => response.data,)
  }

  async post(route, data,) {
    return await this.api.post(route, data,).then((response,) => response.data,)
  }
}
