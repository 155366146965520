import store from '../../store/index'
import { privateApiConnectorService, } from '@/services'

export default {
  name: 'FilterExport',
  data() {
    return { emailFilter: '', suppliersFilter: '', }
  },
  computed: {
    suppliers() {
      return [...new Set(this.$store.getters.getPortalUsers.map((e,) => e.supplier_name,),),]
    },
    isDefaultSupplier() {
      return this.suppliersFilter === 'default'
    },
  },
  methods: {
    onEmailFilterChange() {
      store.commit('setEmailFilter', this.emailFilter.length > 2 ? this.emailFilter : '',)
    },
    onSelectSupplier() {
      store.commit('setSuppliersFilter', this.suppliersFilter || '',)
      if (this.suppliersFilter === '') {
        this.suppliersFilter = 'default'
      }
    },
    onClickExport() {
      privateApiConnectorService.downloadExportUsers(
        this.$store.getters.getAppProvider,
        { email: this.emailFilter, supplier: this.suppliersFilter, },
      )
    },
  },
  mounted() {
    this.suppliersFilter = 'default'
  },
}
