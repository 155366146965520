import _ from 'lodash'
import { privateApiConnectorService, } from '../../../services'

const NO_GROUP_LABEL = 'NON CLASSÉS'

export default {
  name: 'SelectUserMarketSegments',
  props: ['userObject',],
  data() {
    return {
      error: null,
      requestPending: false,
      tabSelection: '0',
      isSelectionValidated: false,
      groupsPanels: [true, true,],
      groupsSeedsSelection: [],
      groupsPhytoSelection: [],
      marketSegmentsPanels: [true, true,],
      marketSegmentsSeedsOpen: [],
      marketSegmentsSeedsSelection: [],
      marketSegmentsPhytoOpen: [],
      marketSegmentsPhytoSelection: [],
    }
  },
  computed: {
    isPpaUser() {
      return this.$store.getters.isPpaUser
    },
    isUserObjectValid() {
      return this.isNotEmpty(this.userObject,)
    },
    groupsSelections() {
      return this.groupGroupsSelection()
    },
    marketSegmentsSelections() {
      return this.groupMarketSegmentsSelection()
    },
    fullMarketList() {
      return this.getListWithoutDuplicateObjects(this.$store.getters.getFullMarketList, [
        'market_segment',
        'group_name',
        'is_semence',
      ],).filter((marketObject,) => this.isPpaUser || this.isScorMarket(marketObject,),)
    },
    marketSegmentsSeeds() {
      return this.groupMarketsByGroupName(this.fullMarketList, true,)
    },
    marketSegmentsPhyto() {
      return this.groupMarketsByGroupName(this.fullMarketList, false,)
    },
    groupsSeeds() {
      return this.getGroups(this.fullMarketList, true,)
    },
    groupsPhyto() {
      return this.getGroups(this.fullMarketList, false,)
    },
  },
  watch: {
    async userObject(newUserObject,) {
      if (this.isNotEmpty(newUserObject,)) {
        const supplierMarketSegments = (await privateApiConnectorService.getSupplierMarketSegmentsList(newUserObject.userId,)) || []
        this.computeMarketSegmentsSelection(supplierMarketSegments,)
        this.computeGroupsSelection(supplierMarketSegments,)
        this.expandAllMarketSegments()
        this.expandAllGroups()
      }
    },
  },
  methods: {
    isScorMarket(marketObject,) {
      return marketObject.market_segmentation_source === 'SCOR'
    },
    isNotEmpty(value,) {
      return !_.isEmpty(value,)
    },
    getListWithoutDuplicateObjects(list, listUnicityAttributes,) {
      return _.uniqBy(list, (object,) => listUnicityAttributes.map((attribute,) => object[attribute],).join(),)
    },
    getListFlattenedByName(list,) {
      return list.map((object,) => object.name,)
    },
    expandAllGroups() {
      this.groupsPanels = [true, true,]
    },
    collapseAllGroups() {
      this.groupsPanels = [false, false,]
    },
    expandAllMarketSegments() {
      this.marketSegmentsPanels = [true, true,]
      this.marketSegmentsPhytoOpen = _.map(this.marketSegmentsPhyto, 'name',)
      this.marketSegmentsSeedsOpen = _.map(this.marketSegmentsSeeds, 'name',)
    },
    collapseAllMarketSegments() {
      this.marketSegmentsPanels = [false, false,]
      this.marketSegmentsPhytoOpen = []
      this.marketSegmentsSeedsOpen = []
    },
    getGroups(fullMarketSegments, isSemence,) {
      return this.getListWithoutDuplicateObjects(
        fullMarketSegments
          .filter((marketSegment,) => marketSegment.is_semence === isSemence,)
          .filter((marketSegment,) => this.isNotEmpty(marketSegment.group_name,),)
          .map((marketSegment,) => {
            return { name: marketSegment.group_name, }
          },),
        ['name',],
      ).sort((a, b,) => a.name.localeCompare(b.name,),)
    },
    getGroupsSelection(fullGroups, supplierMarketSegments,) {
      return fullGroups
        .filter((group,) => supplierMarketSegments.findIndex((supplierMarketSegment,) => group.name === supplierMarketSegment,) !== -1,)
        .map((group,) => group.name,)
    },
    getMarketsSelectionWithoutGroups(marketSegmentsSelection, fullGroups,) {
      const groups = this.getListFlattenedByName(fullGroups,)
      return marketSegmentsSelection.filter((marketSegment,) => groups.indexOf(marketSegment,) === -1 && NO_GROUP_LABEL !== marketSegment,)
    },
    groupMarketsByGroupName(fullMarketSegments, isSemence,) {
      return _(fullMarketSegments,)
        .filter((marketSegment,) => marketSegment.is_semence === isSemence,)
        .groupBy((market,) => market.group_name || NO_GROUP_LABEL,)
        .toPairs()
        .map((groupMarketsPair,) => {
          const [groupName, markets,] = groupMarketsPair
          return {
            name: groupName,
            marketSegments: markets.map((market,) => {
              return { name: market.market_segment, }
            },),
          }
        },)
        .value()
        .sort((a, b,) => a.name.localeCompare(b.name,),)
    },
    getMarketSegmentsSelection(marketsTree, supplierMarketSegments,) {
      return marketsTree
        .reduce((marketSegmentsAccumulator, groupData,) => {
          return marketSegmentsAccumulator.concat(groupData.marketSegments,)
        }, [],)
        .filter(
          (marketSegment,) =>
            supplierMarketSegments.findIndex((supplierMarketSegment,) => marketSegment.name === supplierMarketSegment,) !== -1,
        )
        .map((marketSegment,) => marketSegment.name,)
    },
    groupGroupsSelection() {
      return this.groupsSeedsSelection.concat(this.groupsPhytoSelection,)
    },
    groupMarketSegmentsSelection() {
      const realMarketSegmentsSeedsSelection = this.getMarketsSelectionWithoutGroups(this.marketSegmentsSeedsSelection, this.groupsSeeds,)
      const realMarketSegmentsPhytoSelection = this.getMarketsSelectionWithoutGroups(this.marketSegmentsPhytoSelection, this.groupsPhyto,)
      return realMarketSegmentsSeedsSelection.concat(realMarketSegmentsPhytoSelection,)
    },
    groupAllSelections() {
      return this.groupGroupsSelection().concat(this.groupMarketSegmentsSelection(),)
    },
    computeMarketSegmentsSelection(supplierMarketSegments,) {
      this.marketSegmentsSeedsSelection = this.getMarketSegmentsSelection(this.marketSegmentsSeeds, supplierMarketSegments,)
      this.marketSegmentsPhytoSelection = this.getMarketSegmentsSelection(this.marketSegmentsPhyto, supplierMarketSegments,)
    },
    computeGroupsSelection(supplierMarketSegments,) {
      this.groupsSeedsSelection = this.getGroupsSelection(this.groupsSeeds, supplierMarketSegments,)
      this.groupsPhytoSelection = this.getGroupsSelection(this.groupsPhyto, supplierMarketSegments,)
    },
    validateChoices() {
      this.isSelectionValidated = true
    },
    unvalidateChoices() {
      this.isSelectionValidated = false
    },
    async saveChoices() {
      this.requestPending = true
      try {
        await privateApiConnectorService.updateSupplierMarketSegments(this.userObject.userId, this.groupAllSelections(),)
        this.closePopin()
      } catch (error) {
        this.error =
          'Suite à un problème technique, votre saisie n\'a pas pu être enregistrée. Veuillez nous excuser. Nous mettons tout en oeuvre pour y remédier.'
        console.log(error,)
      } finally {
        this.requestPending = false
      }
    },
    closePopin() {
      this.error = null
      this.isSelectionValidated = false
      this.groupsPanels = [true, true,]
      this.marketSegmentsPanels = [true, true,]
      this.$emit('closePopin',)
    },
  },
}
